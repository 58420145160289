<template>
  <item-form :is-visible="form_visibility">
    <template #hide-trigger>
      <button class="p-2 px-4 bg-yellow-400 form__trigger-hide" @click="hide()">
        <fa-icon icon="times"></fa-icon>
      </button>
    </template>

    <template #default>
      <p class="mb-6 text-xl uppercase tracking-wider">formulir jadwal</p>
      <form @submit.prevent="submit()" class="grid grid-cols-2 gap-2">
        <!-- schedule start -->
        <div class="col-span-2">
          <label for="schedule-start">Mulai</label>
          <datetime
            id="schedule-start"
            title="Tes Mulai"
            placeholder="Tes Mulai"
            class="custom-datepicker"
            :use12-hour="false"
            type="datetime"
            :minute-step="10"
            :min-datetime="new Date().toISOString()"
            v-model="form.started_at"
            :auto="true"
          ></datetime>
        </div>

        <!-- schedule end -->
        <div class="col-span-2">
          <label for="schedule-end">Berakhir</label>
          <datetime
            id="schedule-end"
            title="Tes Berakhir"
            placeholder="Tes Berakhir"
            class="custom-datepicker"
            :use12-hour="false"
            type="datetime"
            :minute-step="5"
            :min-datetime="form.started_at"
            v-model="form.ended_at"
            :auto="true"
          ></datetime>
        </div>

        <!-- schedule quota -->
        <div>
          <label for="schedule-quota">Kuota</label>
          <input type="text" id="schedule-quota" v-model="form.quota" />
        </div>

        <!-- schedule location -->
        <div>
          <label for="schedule-location">Ruangan</label>
          <input type="text" id="schedule-location" v-model="form.location" />
        </div>

        <div class="col-span-2">
          <button class="button mt-6 w-full --primary">
            <fa-icon icon="check"></fa-icon>
            <span class="ml-2">Simpan</span>
          </button>
        </div>
      </form>
    </template>
  </item-form>
</template>


<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import _clone from "lodash/clone";
import { Datetime } from "vue-datetime";
import "vue-datetime/dist/vue-datetime.css";
import { Settings } from "luxon";

export default {
  data() {
    return {
      form: {
        id: null,
        quota: 0,
        location: null,
        started_at: null,
        ended_at: null,
      },
      formDefault: {},
    };
  },
  methods: {
    hide() {
      this.form = _clone(this.formDefault);
      this.HIDE_FORM();
      this.UNSET_FORM_DATA();
    },
    async submit() {
      // store when id is null
      if (this.form.id === null) {
        await this.storeSchedule(this.form);
      } else {
        await this.updateSchedule(this.form);
      }
      this.hide();
    },
    ...mapActions("schedule", ["storeSchedule", "updateSchedule"]),
    ...mapMutations("schedule", ["HIDE_FORM", "UNSET_FORM_DATA"]),
  },
  computed: {
    ...mapGetters("schedule", [
      "form_visibility",
      "selected_schedule",
      "form_data",
    ]),
  },
  watch: {
    form_data(val) {
      this.form = val === null ? _clone(this.formDefault) : _clone(val);
    },
  },
  mounted() {
    this.formDefault = _clone(this.form);
    Settings.defaultLocale = "id";
  },
  beforeDestroy() {},
  components: {
    ItemForm: () => import("@/components/Common/ItemForm.vue"),
    Datetime,
  },
  name: "ScheduleForm",
};
</script>